import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';

import '../../../../shared/components/custom-content/faq-list/faq-list.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { inputStyles } from '@brightspace-ui/core/components/inputs/input-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { SUPPORT_CATEGORIES } from '../../../../../shared/constants.js';

export default class ContactSupport extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      params: { type: Object },
      query: { type: Object },
      _name: { type: String, attribute: false },
      _email: { type: String, attribute: false },
      _submitted: { type: Boolean, attribute: false },
      _category: { type: String, attribute: false },
      _message: { type: String, attribute: false },
      _faqs: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      heading3Styles,
      inputLabelStyles,
      inputStyles,
      selectStyles,
      css`
        :host {
          display: block;
        }

        .contact-form {
          display: grid;
          gap: 20px;
          grid-template-columns: 1fr 1fr;
          max-width: 500px;
          padding-bottom: 20px;
        }

        .textarea-wrapper, .select-wrapper {
          grid-column: 1 / span 2;
        }

        .select-wrapper select {
          width: 100%;
        }

        d2l-form {
          padding-bottom: 20px;
        }

        @media (max-width: 767px) {
          .contact-form {
            grid-template-columns: minmax(0, 1fr);
            width: 100%;
          }

          .textarea-wrapper, .select-wrapper {
            grid-column: 1;
          }

          .category-select {
            width: 100%;
          }

          .d2l-heading-1 {
            margin: 0;
          }
        }
      `,
    ];
  }

  constructor() {
    super();
    this._submitted = false;
    this._category = '';
    this._message = '';
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    this._name = this.session.user.getDisplayName() || '';
    this._email = this.session.user.getEmail() || '';
    this._faqs = await this.client.getCustomFAQContent(this.session?.tenantId);
    this.client.logEvent({ eventType: 'contactSupportPageViewed' });
  }

  get _allowEmailEdit() {
    return this.session.tenant?.hasTag('allowEmailEdit');
  }

  get _isSubmitDisabled() {
    return !this._category || !this._message || !this._name || !this._email;
  }

  _isValidEmail(email) {
    // This regex validates basic email structure:
    // - [^@\\s]+ : Matches one or more characters that are not '@' or whitespace before the '@' symbol (local part of the email).
    // - @ : Matches the literal '@' symbol.
    // - [^@\\s]+ : Matches one or more characters that are not '@' or whitespace after the '@' symbol (domain part).
    // - \\. : Matches a literal '.' symbol (dot).
    // - [^@\\s]+ : Matches one or more characters that are not '@' or whitespace after the dot (top-level domain).
    const emailRegex = new RegExp('[^@\\s]+@[^@\\s]+\\.[^@\\s]+');
    return emailRegex.test(email);
  }

  _handleNameChange(e) {
    this._name = e.target.value;
    this.requestUpdate();
  }

  _handleEmailChange(e) {
    this._email = e.target.value;
    this.requestUpdate();
  }

  _handleCategoryChange(e) {
    this._category = e.target.value;
    this.requestUpdate();
  }

  _handleMessageChange(e) {
    this._message = e.target.value;
    this.requestUpdate();
  }

  render() {
    const faqs = this._faqs?.hasContent ? html`<faq-list .faqs=${this._faqs.value}></faq-list>` : nothing;
    return html`
      <h1 class="d2l-heading-1">${this.localize('contact-support.title')}</h1>
      ${this._submitted ? html`
      <d2l-alert type="default">${this.localize('contact-support.thanksForMessage')}</d2l-alert>
      ` : html`
      <p>${this.localize('contact-support.description')}</p>
      <d2l-form>
        <div class="contact-form">
          <d2l-input-text
            id="name"
            required
            .value="${this._name}"
            @input="${this._handleNameChange}"
            title=""
            label=${this.localize('contact-support.form.name')}></d2l-input-text>
          <d2l-input-text
            id="email"
            required
            ?disabled=${!this._allowEmailEdit}
            .value="${this._email}"
            aria-invalid="${this._email && !this._isValidEmail(this._email)}"
            @input="${this._handleEmailChange}"
            title=""
            type="email"
            label=${this.localize('contact-support.form.email')}></d2l-input-text>
          <div class="select-wrapper">
            <label for="category" class="d2l-input-label d2l-input-label-required">${this.localize('contact-support.form.questionType')}</label>
            <select
              id="category"
              name="category"
              class="d2l-input-select category-select"
              aria-label="${this.localize('contact-support.form.questionType')}"
              @change="${this._handleCategoryChange}">
              <option value="" selected disabled hidden> </option>
              ${Object.keys(SUPPORT_CATEGORIES).map(key => html`
              <option value="${key}">${this.localize(SUPPORT_CATEGORIES[key])}</option>
              `)}
            </select>
          </div>
          <div class="textarea-wrapper">
            <d2l-input-textarea
              id="message"
              label=${this.localize('contact-support.form.textField')}
              required
              title=""
              .skeleton=${this.skeleton}
              @input="${this._handleMessageChange}"></d2l-input-textarea>
          </div>
        </div>
        <d2l-button
          primary
          id="contact-support"
          class="d2l-skeletize"
          ?disabled="${this._isSubmitDisabled}"
          @click="${this._contactSupport}">${this.localize('contact-support.button')}</d2l-button>
      </d2l-form>
      `}
      ${faqs}
    `;
  }

  async _contactSupport() {
    const { user } = this.session;
    const { title, department, entitlements, tenantType } = user;
    const userInfo = { title, department, entitlements, tenantType, email: this._email };

    if (this._name !== this.session.user.getDisplayName()) {
      userInfo.submittedName = this._name;
    }
    await this.client.contactSupport(userInfo, this._message, this._category);
    this._submitted = true;
  }

}

window.customElements.define('contact-support', ContactSupport);
