// Helper methods and constants for Playwright
import { statuses as appStatuses } from '../models/application/index.js';

const idVocabulary = {
  activity: 'acty',
  activities: 'acty',
  admin: 'adm',
  administration: 'adm',
  application: 'appn',
  applications: 'appn',
  approve: 'appv',
  approver: 'appv',
  approval: 'appv',
  approvals: 'appv',
  attribute: 'attb',
  attributes: 'attb',
  button: 'btn',
  callToAction: 'cta',
  cancel: 'cncl',
  card: 'card',
  career: 'carr',
  carousel: 'crsl',
  checkbox: 'chbx',
  confirm: 'cfrm',
  confirmation: 'cfrm',
  content: 'ctnt',
  contents: 'ctnt',
  container: 'ctnr',
  decline: 'dcln',
  detail: 'dtl',
  details: 'dtl',
  dialog: 'dlg',
  dropdown: 'dpdn',
  employee: 'lrnr',
  filter: 'fltr',
  finance: 'fin',
  financial: 'fin',
  goal: 'goal',
  hosted: 'hstd',
  icon: 'icon',
  image: 'img',
  item: 'item',
  learner: 'lrnr',
  left: 'lt',
  lineItem: 'litm',
  linkFrom: 'link-from',
  linkTo: 'link-to',
  list: 'list',
  logo: 'logo',
  manager: 'mngr',
  menu: 'menu',
  name: 'name',
  next: 'nxt',
  overview: 'ovw',
  payment: 'pymt',
  payments: 'pymt',
  playwright: 'pw',
  previous: 'prv',
  profile: 'prfl',
  program: 'prgm',
  programs: 'prgm',
  progress: 'pgrn',
  progression: 'prgn',
  reason: 'reas',
  requester: 'rqtr',
  requests: 'appn',
  right: 'rt',
  role: 'role',
  row: 'row',
  skill: 'skll',
  skills: 'skll',
  step: 'st',
  sponsor: 'spsr',
  submit: 'sbmt',
  submisson: 'sbmt',
  table: 'tbl',
  tag: 'tag',
  termsAndConditions: 'tc',
  text: 'txt',
  title: 'ti',
  user: 'user',
  users: 'user',
};

const v = idVocabulary;
const pw = v.playwright;

const GENERATED_TEST_ID_CATEGORIES = {
  detailItemText: `${pw}-${v.detail}-${v.item}-${v.text}`,
};

const STATIC_TEST_IDS = {
  // These IDs are used in Playwright tests
  activitiesLink: `${pw}-${v.linkTo}-${v.activities}`,
  applicationTableRow: `${pw}-${v.application}-${v.table}-${v.row}`,
  applicationOverviewActivityName:
    `${pw}-${v.application}-${v.overview}-${v.activity}-${v.name}`,
  approvalOverview: `${pw}-${v.approval}-${v.overview}`,
  approvalReason: `${pw}-${v.approval}-${v.reason}`,
  careerFilterTag: `${pw}-${v.career}-${v.filter}-${v.tag}`,
  careerSkillsList: `${pw}-${v.career}-${v.skills}-${v.list}`,
  confirmationDialog: `${pw}-${v.confirmation}-${v.dialog}-${v.attributes}`,
  nextProgramsButton: `${pw}-${v.next}-${v.programs}-${v.button}`,
  previousProgramsButton: `${pw}-${v.previous}-${v.programs}-${v.button}`,
  profileDropdownMenu: `${pw}-${v.profile}-${v.dropdown}-${v.menu}`,
  requestsLink: `${pw}-${v.linkTo}-${v.requests}`,
  termsAndConditionsText: `${pw}-${v.termsAndConditions}-${v.text}`,

  // These IDs are fixed on third-party pages
  hostedPaymentSubmitButton: 'hosted-payment-submit-button', // fixed on Stripe page

  // These IDs are defined but not used in any known Playwright tests
  linkFromLogo: `${pw}-${v.linkFrom}-${v.logo}`,
  profileImage: `${pw}-${v.profile}-${v.image}`,
};

const ICON_CLASSES = {
  approvedCheck: 'approved-check',
  stepCompleted: 'd2l-labs-single-step-header-done-icon',
  stepInProgress: 'd2l-labs-single-step-header-inner-progress-circle',
  stepNotStarted: 'd2l-labs-single-step-header-circle',
};

const PROGRESS_WIZARD_STATES = {
  notStarted: 'not started',
  inProgress: 'in progress',
  completed: 'completed',
};

const APPLICATION_STATUS_INDICATOR_STATES = {
  'approved': appStatuses.APPROVED.indicator.state,
  'cancelled': appStatuses.CANCELLED_AFTER_REGISTRATION.indicator.state,
  'completed': appStatuses.COMPLETED.indicator.state,
  'declined': appStatuses.DECLINED.indicator.state,
  'failed': appStatuses.FAILED.indicator.state,
  '1 of 2 approved' : appStatuses.ONE_OF_TWO_APPROVED.indicator.state,
  'paid' : appStatuses.REGISTRATION_SUBMITTED.indicator.state,
  'pending': appStatuses.PENDING.indicator.state,
  'registered': appStatuses.REGISTERED.indicator.state,
  'registration started': appStatuses.REGISTRATION_SUBMITTED.indicator.state,
  'withdrew': appStatuses.WITHDRAWN.indicator.state,
};

const EMPTY_MONEY_TEXT = '---';

/**
 * Generates a Playwright data-testid value when a constant one can't be
 * used.
 *
 * @param {String} category - loose, arbitrary category of element being labelled
 * @param {String} label - distinct identifier for this element category on this page
 * @returns {String} - kebab-case test ID suitable for use with Playwright
 */
const makePlaywrightTestId = (category = '', label = '') => {
  if (!(Object.values(GENERATED_TEST_ID_CATEGORIES).includes(category))) {
    console.warn(`'${category}' is not a defined category value for generating Playwright test IDs. Using it, but consider using or defining a category in helpers/playwright.js.`);
  }
  return [category, label].join(' ')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase()
    .replace(/[^-a-z0-9]/g, '');
};

export {
  APPLICATION_STATUS_INDICATOR_STATES,
  EMPTY_MONEY_TEXT,
  GENERATED_TEST_ID_CATEGORIES,
  ICON_CLASSES,
  makePlaywrightTestId,
  PROGRESS_WIZARD_STATES,
  STATIC_TEST_IDS
};
