import '@brightspace-ui/core/components/typography/typography.js';

import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/button/button.js';

import { bodyCompactStyles, bodyStandardStyles, heading4Styles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement } from 'lit';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaPermissionMixin } from '../../../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';
import { TOS_MAX_CHARS } from '../../../../../../shared/constants.js';

export default class CustomTerms extends NovaPermissionMixin(LocalizeNova(RequesterMixin(LitElement))) {
  static get properties() {
    return {
      tenantId: { type: String },
      employerName: { type: String },
      _condition: { type: Object, attribute: false },
      _terms: { type: String, attribute: false },
      _isEnabled: { type: Boolean, attribute: false },
    };
  }

  static get styles() {
    return [
      heading4Styles,
      bodyCompactStyles,
      bodyStandardStyles,
      inputLabelStyles,
      labelStyles,
      selectStyles,
      css`
        .sectioned-big {
          margin-bottom: 1.5rem;
          margin-top: 1.5rem;
        }

        .sectioned-small {
          margin-top: 1rem;
        }

        [disabled] {
          opacity: 0.5;
        }

        .width {
          width: 100%;
        }

        .length {
          font-weight: normal;
        }
`,
    ];
  }

  constructor() {
    super();
    this._isEnabled = false;
    this._terms = '';
    this.tenantId = '';
    this.employerName = '';
    this._condition = undefined;
    this._language = 'en';
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  firstUpdated() {
    this._setConditionProp();
  }

  async _getCustomCondition() {
    return this.client.getCustomContent(this.tenantId, 'customCondition', this._language, true);
  }

  async _setConditionProp() {
    this._condition = await this._getCustomCondition() || undefined;
    this._terms = this._condition?.value || '';
    this._isEnabled = this._condition?.isEnabled;
  }

  async _languageChange(e) {
    this._language = e.target.value;
    await this._setConditionProp();
  }

  render() {
    return html`
      <section>
        <div class="header">
          <h3>${this.localize('admin.custom-terms-of-use.heading')}</h3>
          <p class="d2l-body-standard">
            ${this.localize('admin.custom-terms-of-use.explanation', { employerName: this.employerName, bold: this.l10nTags.bold() })}
          </p>
        </div>
        <p class="d2l-body-standard">
          ${this.localize('admin.custom-terms-of-use.optional-statement')}
        </p>
        ${this._languageSelector}
        <d2l-input-checkbox
          ?checked=${this._isEnabled}
          @change=${this._toggleCustomCondition}
          class="sectioned-big">
          ${this.localize('admin.custom-terms-of-use.input.enable-label')}
        </d2l-input-checkbox>
        <label
          aria-hidden="true"
          class="d2l-input-label"
          for="custom-condition-input">
          ${this.localize('admin.custom-terms-of-use.input.instructions', { charLimit: TOS_MAX_CHARS })}
        </label>
        <d2l-input-textarea
          id="custom-condition-input"
          label="${this.localize('admin.custom-terms-of-use.input.instructions', { charLimit: TOS_MAX_CHARS })}"
          label-hidden
          ?disabled=${!(this._isEnabled)}
          value=${this._terms}
          @input=${this._onCustomConditionChange}
          @keyup=${this._validateInput}
          placeholder="${this.localize('admin.custom-terms-of-use.input.instructions.placeholder')}">
        </d2l-input-textarea>
        <d2l-button
          primary
          @click=${this._saveCustomCondition}
          ?disabled=${this._terms?.length > TOS_MAX_CHARS}
          class="sectioned-small">
          ${this.localize('general.button-text.save')}
        </d2l-button>
        <h4 ?disabled=${!this._isEnabled} class="d2l-heading-4">${this.localize('admin.custom-terms-of-use.preview.heading')}</h4>
        <p ?disabled=${!this._isEnabled} class="d2l-label-text">
          ${this.localize('apply-activity.customCondition')}
        </p>
        <d2l-input-checkbox class="width" ?disabled=${!this._isEnabled}>
          <span ?disabled=${(this._isEnabled) && (!this._terms)}>${this._previewText}</span>
        </d2l-input-checkbox>
      </section>
    `;
  }

  get _languageSelector() {
    return html`
      <p class="d2l-body-standard">${this.localize('admin.custom-terms-of-use.language.intro')}</p>
      <select id="language" class="d2l-input-select" @change=${this._languageChange}>
          <option value="en" ?selected=${this._language === 'en'}>${this.localize('general.language.english')}</option>
          <option value="fr" ?selected=${this._language === 'fr'}>${this.localize('general.language.french')}</option>
      </select>
    `;
  }

  // Show placeholder text for preview checkbox when no custom condition text is entered
  get _previewText() {
    return this._terms || this.localize('admin.custom-terms-of-use.preview.text');
  }

  _validateInput({ target }) {
    const value = target.value.trim();
    if (value.length > TOS_MAX_CHARS) {
      target.setAttribute('invalid', '');
    } else {
      target.removeAttribute('invalid');
    }
  }

  _onCustomConditionChange(e) {
    this._terms = e.target.value;
  }

  async _saveCustomCondition() {
    const newCondition = {
      isEnabled: this._isEnabled,
      value: this._terms,
      type: 'customCondition',
      tenantId: this.tenantId,
      lang: this._language,
    };

    this._condition = await this.client.setCustomContent(this.tenantId, newCondition);
    const message = this._isEnabled
      ? this.localize('admin.custom-terms-of-use.alert.enabled')
      : this.localize('admin.custom-terms-of-use.alert.disabled');
    this.session.toast({ type: 'default', message, noAutoClose: false });
  }

  _toggleCustomCondition(e) {
    this._isEnabled = e.target.checked;
  }
}

window.customElements.define('custom-terms', CustomTerms);
