import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '../../../../shared/components/inputs/nova-checkbox-input/nova-checkbox-input.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading2Styles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaFormMixin } from '../../../../shared/mixins/nova-form-mixin/nova-form-mixin.js';
import { STATIC_TEST_IDS } from '../../../../../shared/helpers/playwright.js';

class ApplicationTermsCheckboxes extends NovaFormMixin(SkeletonMixin(LocalizeNova(LitElement))) {

  static get properties() {
    return {
      customCondition: { type: Object },
      employerName: { type: String, attribute: 'employer-name' },
      language: { type: String },
      providerName: { type: String, attribute: 'provider-name' },
      _canSubmitRequest: { state: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      heading2Styles,
      labelStyles,
      linkStyles,
      css`
        :host {
          display: block;
        }

        .tos {
          height: 580px;
          width: 100%;
        }

        .terms-button {
          background: none;
          border: none;
          display: inline;
          font-family: 'Lato', sans-serif;
          font-size: inherit;
          letter-spacing: inherit;
          padding: 0;
        }

        #submit-button {
          margin-top: 3.6rem;
        }
`,
    ];
  }

  constructor() {
    super();

    this.customCondition = null;
    this.employerName = this.localize('apply-activity.employer');
    this.language = 'en';
    this.providerName = this.localize('apply-activity.provider');

    this._isAccepted = false;

    this._termsOfUseChecked = false;
    this._customConditionChecked = false;

    this._canSubmitRequest = false;

    // Initialize NovaFormMixin properties
    this.hideFormErrorSummary = true;
    this.showNovaErrorSummary = true;
    this.showErrorToast = false;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return html`
      <div class="conditions-checkbox-container">
        <h2 id="terms-title" class="d2l-skeletize d2l-heading-2">
          ${this.localize('apply-activity.agree.title')}
        </h2>
        <d2l-form id="tos-form">
          ${this._standardConditionsTemplate()}
          ${this._customConditionTemplate()}
          <d2l-button
            primary
            id="submit-button"
            @click=${this._applyClicked}
            ?disabled=${!this._canSubmitRequest || this._submitting}
            .skeleton=${this.skeleton}>
            ${this.localize('apply-activity.submit.button')}
          </d2l-button>
          <d2l-button
            @click=${this._backClicked}
            .skeleton=${this.skeleton}>
            ${this.localize('review-application.back.button')}
          </d2l-button>
        </d2l-form>
      </div>
      ${this._tosDialogTemplate()}
    `;
  }

  _closeDialog() {
    this.showTOS.opened = false;
  }

  _checkboxesAccepted() {
    return this._termsOfUseChecked
      && (!this.customCondition?.isEnabled || this._customConditionChecked);
  }

  /**
   * @returns {import('lit').TemplateResult}
   */
  _customConditionTemplate() {
    if (!this.customCondition?.isEnabled) return nothing;
    return html`
      <p class="d2l-label-text d2l-skeletize">
        ${this.localize('apply-activity.customCondition')}
      </p>
      <nova-checkbox-input
        id="customCondition"
        @change="${this._termsCheckboxChanged}"
        required
        hide-tooltip
        ?value="${this._customConditionChecked}">
        ${this.customCondition.value}
      </nova-checkbox-input>
    `;
  }

  async _applyClicked() {
    const isFormValidated = await super.isFormValidated();
    if (!isFormValidated) {
      this.novaErrorSummary.errors = ['You need to agree to all the terms and conditions'];
      this.update();
    } else {
      document.dispatchEvent(new CustomEvent('apply-clicked', {
        bubbles: true,
        composed: true,
      }));
    }
  }

  _backClicked() {
    document.dispatchEvent(new CustomEvent('back-clicked', {
      bubbles: true,
      composed: true,
    }));
  }

  get showTOS() {
    if (!this._showTOS) this._showTOS = this.shadowRoot.getElementById('show-tos-dialog');
    return this._showTOS;
  }

  _openDialog(e) {
    e.preventDefault();
    this.showTOS.opened = true;
  }

  _standardConditionsTemplate() {
    const termsOfUseDialogButton = html`
      ${this.localize('apply-activity.terms.agree')}
      <button class="d2l-skeletize d2l-link terms-button" @click=${this._openDialog}>
        ${this.localize('apply-activity.terms.link')}
      </button>
    `;

    const termsOfUse = this.localize('apply-activity.termsOfUse', { employer: this.employerName, provider: this.providerName });

    return html`
    <nova-checkbox-input
      id="termsOfUse"
      @change="${this._termsCheckboxChanged}"
      required
      hide-tooltip
      .skeleton=${this.skeleton}
      ?value="${this.termsOfUseChecked}"
    >
      ${termsOfUseDialogButton}
      <span data-testid="${STATIC_TEST_IDS.termsAndConditionsText}">${termsOfUse}</span>
    </nova-checkbox-input>
  `;
  }

  _termsCheckboxChanged(e) {
    const targetKey = `_${e.target.id}Checked`;
    this[targetKey] = !this[targetKey];

    this._canSubmitRequest = this._checkboxesAccepted();
  }

  get termsOfUseUrl() {
    const lang = this.language === 'fr' ? 'fr' : 'en';
    const type = this.session?.isPublicPortal ? 'public-' : '';
    return `/assets/tos/terms-of-use-${type}${lang}.html`;
  }

  _tosDialogTemplate() {
    return html`
      <d2l-dialog id="show-tos-dialog" title-text="${this.localize('apply-activity.tos')}">
        <iframe
          src="${this.termsOfUseUrl}"
          frameborder="0"
          scrolling="auto"
          aria-live="assertive"
          class="tos">
        </iframe>
        <d2l-button
          slot="footer"
          @click=${this._closeDialog}
          data-dialog-action>
          ${this.localize('apply-activity.buttonClose')}
        </d2l-button>
      </d2l-dialog>
    `;
  }
}

window.customElements.define('application-terms-checkboxes', ApplicationTermsCheckboxes);
