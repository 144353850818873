export function initBraze(brazeAppId, userGuid, contentCardsSubscription) {
  if (window.braze || !brazeAppId) return Promise.resolve();

  return import('@braze/web-sdk')
    .then(braze => {
      braze.initialize(brazeAppId, { baseUrl: 'https://sdk.iad-07.braze.com' });
      braze.changeUser(userGuid);
      braze.automaticallyShowInAppMessages();
      braze.subscribeToContentCardsUpdates(contentCardsSubscription);
      braze.openSession();
      window.braze = braze;
    })
    .catch(error => {
      console.error('Failed to load Braze SDK:', error);
    });
}
